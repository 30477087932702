import React from 'react'
import Helmet from 'react-helmet'
import { Link } from "gatsby"

import Layout from '../components/layout'
 
import pic23 from '../assets/images/p23.jpg'

const Creatividad = ({props, data}) => (
    <Layout>
        <Helmet>
            <title>Creatividad - RevelARTE</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="bgtitle" style={{backgroundImage: `url(${pic23})`}} >
 
                        <h1>Creatividad</h1>
                    </header>

                    
                    <div class="grid-wrapper">
                        
                        <div class="col-8"><h3>¿Por qué proponemos trabajar la creatividad?</h3>
                        
                        
                        <p>Para Fidel Moccio todos somos creativos, pero la creatividad permanecerá en estado
latente, si no tenemos algún recurso que sirva como catalizador  o que permita que ésta se haga visible.  Ser consciente para este autor es poder tener mayor conocimiento de nuestro cuerpo, del sentir y de lo que se vive, seguido de las imágenes que nos ubican en tiempo y espacio de lo que acontece en nuestra mente.</p></div>

<div class="col-4"><blockquote>“Esto implica un esfuerzo, un desarrollo, un cambio de  hábitos que
harán del hombre un ser que pasará de hombre- máquina hacia un ser singular, único,
creador” (F. Moccio)
</blockquote></div>


<div class="col-8"><p>Para Rolnik el sistema muchas veces se sostiene en la apropiación de nuestra propia vida, de la posibilidad de crear y de transformarnos, de ese impulso vital que tenemos de cambiar. Esta imposición opera en nuestro inconsciente, es por ello que es necesaria  la investigación de nuestra propia experiencia subjetiva. También nos plantea la presencia de los “afectos”  las fuerzas del mundo que operan en todo y nos influyen, nos conectan con otres, con todo  y se registran en nuestro
propio cuerpo.</p></div>


<div class="col-4"><blockquote>.... “Habrá que buscar vías de
acceso a la potencia de la creación en nosotros mismos, es necesario también tomar para
uno la responsabilidad como ser vivo y luchar por la reapropiación de las potencias de creación y de cooperación..” (S. Rolnik)
</blockquote></div>

                    <div class="col-8"><p>Es en esta mezcla improvisada donde surgen las posibilidades de reinvetarnos, de conocernos, experimentar nuevas oportunidades y crear. Somos más que nuestra experiencia como sujetos, o nuestra subjetividad. Somos también seres vivientes, con experiencias que vienen de la conexión con el afuera. Es con el cuerpo (y nosotras sumamos) con los sentidos que podemos percibir estas experiencias o lo que ella llama “afectos”. Al ser nuevas pueden generar sensación de
extrañamiento, pero también habilitar instancias de creación hasta entonces
desconocidas. <br/><br/>
De estos autores tomamos que es nuestra responsabilidad como ser vivo volver por la
reapropiación de nuestras potencias, explorar y jugar con el cuerpo como instrumento principal de registro y fuerza y la importancia de generar espacios que permitan  ir a un conocimiento personal, consciente, y colectivo, donde los nuevos estímulos son vistos como nuevas posibilidades.
</p></div>
                     


                    <div class="col-4"><blockquote>
                    ...“En nuestra condición de vivientes somos constituidos por los efectos de la fuerza del flujo de lo vital y sus relaciones diversas y mutables que agitan la forma de un mundo. Tales fuerzas alcanzan simultáneamente a todos los cuerpos que la componen-humanos y no humanos- haciendo de ellos un solo cuerpo...podemos designar esos efectos como “afectos… la evaluación de los afectos se hace por la intuición...o el propio saber del cuerpo.” (S. Rolnik)

</blockquote></div>
                    </div>


                    <ul className="actions">
                                 <li><Link to="/elarte" className="button special big">Arte</Link></li>
                                <li><Link to="/saludmental" className="button special big">Salud Mental</Link></li>

                            </ul>
                           
                    </div>
                
 




            </section>
        </div>

    </Layout>
)




export default Creatividad